import React, { useEffect, useState } from 'react';

import { Button, Tooltip, Icon } from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks/user';
import { useTimeTracking } from 'src/hooks/useTimeTracking';

import differenceInSeconds from 'date-fns/differenceInSeconds';

import { TimeTrackingStatus, TimeTrackingOperation } from 'src/generated';

import * as Styled from './styles';

let interval;

const TimeTracking = () => {
  const user = useCurrentUser();
  const { onUpdateTimeTracking } = useTimeTracking({
    taskId: user?.timeTracking?.actionId._id,
  });

  const [time, setTime] = useState({
    sec: 0,
    min: 0,
    hr: 0,
  });

  useEffect(() => {
    clearInterval(interval);

    if (user?.timeTracking) {
      const { endTime, totalTime } = user.timeTracking;

      const secondsToNow = differenceInSeconds(
        new Date(),
        new Date(user.timeTracking.startTime),
      );

      const totalTimeToNow =
        endTime === null ? secondsToNow + totalTime : totalTime;

      const hours = Math.floor(totalTimeToNow / 3600);
      const minutes = Math.floor((totalTimeToNow - hours * 3600) / 60);
      const seconds = totalTimeToNow - hours * 3600 - minutes * 60;

      setTime({
        sec: seconds,
        min: minutes,
        hr: hours,
      });

      if (user.timeTracking.status === TimeTrackingStatus.Tracking) {
        interval = setInterval(updateTimer, 1000);
      }
    }
  }, [user]);

  const updateTimer = () => {
    setTime(prev => {
      let newTime = { ...prev };
      // update sec and see if we need to increase min
      if (newTime.sec < 59) newTime.sec += 1;
      else {
        newTime.min += 1;
        newTime.sec = 0;
      }
      // min has increased in *newTime* by now if it was updated, see if it has crossed 59
      if (newTime.min === 60) {
        newTime.min = 0;
        newTime.hr += 1;
      }

      return newTime;
    });
  };

  if (!user?.timeTracking) return null;

  const {
    _id: timeTrackingId,
    status,
    actionId: { _id, title },
  } = user.timeTracking;

  const handleUpdateTaskTimeTracking = ({ operation }) => {
    clearInterval(interval);
    onUpdateTimeTracking({
      actionId: _id,
      operation,
      timeTrackingId,
    });
  };

  return (
    <Tooltip overlay={title} placement="bottom">
      <Styled.TimeTracking gap={6} alignItems="center">
        <Styled.TimeTrackingText>{`${time.hr < 10 ? 0 : ''}${time.hr}:${
          time.min < 10 ? 0 : ''
        }${time.min}:${time.sec < 10 ? 0 : ''}${
          time.sec
        }`}</Styled.TimeTrackingText>

        {(status === TimeTrackingStatus.Stopped ||
          status === TimeTrackingStatus.Paused) && (
          <Button
            size="small"
            htmlType="text"
            onClick={() =>
              // RESUME
              handleUpdateTaskTimeTracking({
                operation: TimeTrackingOperation.Start,
              })
            }
          >
            <Icon name="play-square-fill" />
          </Button>
        )}
        {status === TimeTrackingStatus.Tracking && (
          <Button
            size="small"
            htmlType="text"
            onClick={() =>
              // PAUSE
              handleUpdateTaskTimeTracking({
                operation: TimeTrackingOperation.Pause,
              })
            }
          >
            <Icon name="pause-square-fill" />
          </Button>
        )}
        {status !== TimeTrackingStatus.Stopped && (
          <Button
            size="small"
            htmlType="text"
            onClick={() =>
              // STOP
              handleUpdateTaskTimeTracking({
                operation: TimeTrackingOperation.Stop,
              })
            }
          >
            <Icon name="stop-circle-fill" />
          </Button>
        )}
      </Styled.TimeTracking>
    </Tooltip>
  );
};

export default TimeTracking;
