import styled, { css } from 'styled-components';

import { handleMarginOrPadding } from 'src/utils/handleMarginOrPadding';

interface BoxProps {
  inline?: boolean;
  height?: string;
  maxHeight?: string;
  width?: string;
  maxWidth?: string;
  bgColor?: string;
  /* padding and margin */
  padding?: number | number[];
  margin?: number | number[];
  bold?: boolean;
}

const Box = styled.div<BoxProps>`
  ${({
    inline,
    height,
    maxHeight,
    bgColor,
    width,
    maxWidth,
    padding,
    margin,
    bold,
  }) => css`
    display: block;
    ${inline &&
    css`
      display: inline-block;
    `}
    ${height &&
    css`
      height: ${height};
    `}
    ${maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
    ${width &&
    css`
      width: ${width};
    `}
    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
    ${bgColor &&
    css`
      background-color: ${bgColor};
    `}
    ${bold &&
    css`
      font-weight: bold;
    `}
    ${handleMarginOrPadding(margin)}
    ${handleMarginOrPadding(padding, true)}
  `}
`;

export default Box;
