import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Button, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useNotifications } from 'src/hooks/useNotifications';

import { openNotifications } from 'src/graphql/vars';

import * as Styled from './styles';

const TopNotificationButton = () => {
  const isOpenNotifications = useReactiveVar(openNotifications);
  const theme = useTheme();
  const { unreadCount } = useNotifications({});

  const handleToggleNotificationsDrawer = e => {
    // TODO - Fix this
    e.preventDefault();
    e.stopPropagation();
    openNotifications(!isOpenNotifications);
  };

  return (
    <Styled.NotificationBlock>
      {!!unreadCount && (
        <Styled.Count alignItems="center" justifyContent="center">
          {unreadCount}
        </Styled.Count>
      )}
      <Button circle htmlType="text" onClick={handleToggleNotificationsDrawer}>
        <Icon
          size={20}
          name="notification"
          color={
            isOpenNotifications ? theme.color.success : theme.color.grey500
          }
        />
      </Button>
    </Styled.NotificationBlock>
  );
};

export default TopNotificationButton;
