import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Flex, Skeleton } from 'src/components/design-system';

import { useSubtasks } from 'src/hooks/useSubtasks';
import { useTask } from 'src/hooks/useTask';

import Subtask from './SubTask';
import { dndReorder } from 'src/utils/dnd';

import { AddSubtaskInput } from './styles';

const SubTasks = ({ action }) => {
  const { _id, statusId } = action;
  const [value, setValue] = useState('');
  const { onCreateTask } = useTask({ parentId: _id });
  const { loading, subtasks, onGoToSubtask, onReorderSubtasks } = useSubtasks();

  const handleCreateSubtask = e => {
    e.preventDefault();
    onCreateTask({
      parentId: _id,
      statusId,
      title: value,
    });
    setValue('');
  };

  const handleDragEnd = result => {
    const { destination, source } = result;

    const reorderedSubTaskIds = dndReorder(
      subtasks,
      source.index,
      destination.index,
    ).map(({ _id }) => _id);

    onReorderSubtasks({
      taskId: _id,
      subtaskOrder: reorderedSubTaskIds,
    });
  };

  if (loading) {
    return (
      <Flex gap={10} direction="column">
        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} />
      </Flex>
    );
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {subtasks?.map((task, index) => (
                <Draggable key={task._id} draggableId={task._id} index={index}>
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      const offset = { x: 460, y: 44 }; // your fixed container left/top position
                      const x =
                        // @ts-ignore
                        provided?.draggableProps?.style?.left - offset.x;
                      // @ts-ignore
                      const y = provided?.draggableProps?.style?.top - offset.y;
                      // @ts-ignore
                      provided.draggableProps.style.left = x;
                      // @ts-ignore
                      provided.draggableProps.style.top = y;
                    }
                    return (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Subtask
                          key={task._id}
                          task={task}
                          onGoToSubtask={onGoToSubtask}
                          dragHandleProps={provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <form onSubmit={handleCreateSubtask}>
        <AddSubtaskInput
          type="text"
          name="subtask"
          placeholder="Add new subtask"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </form>
    </div>
  );
};

export default SubTasks;
