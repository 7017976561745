import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { useApolloClient, useLazyQuery } from '@apollo/client';

import { Box, Select, Button, Flex } from 'src/components/design-system';

import { useCurrentUser } from '../../../../graphql/hooks';
import { useProjectFilter } from 'src/hooks/custom';

import { priorities } from 'src/common/constants';

import { useProject } from 'src/utils/apollo';

import { dueDateOptions, DEFAULT_FILTER } from './constants';
import {
  GET_PROJECT_TASKS_FOR_BOARD_VIEW,
  GET_TASKS_WITH_SUBTASKS,
} from 'src/graphql/actions';

import * as Styled from './styles';

const ProjectFilter = () => {
  const user = useCurrentUser();

  const { workspaceId } = user;
  const { id: projectId } = useParams() as any;
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');

  const { t } = useTranslation();
  const { project, statuses, labels } = useProject();
  const client = useApolloClient();

  const { isFilterActive, filter, updateFilter } = useProjectFilter();

  const [getFilteredTask] = useLazyQuery(GET_PROJECT_TASKS_FOR_BOARD_VIEW, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      client.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId },
        data: {
          kanbanTasks: data.kanbanTasks,
        },
      });
    },
  });

  const [getTasks] = useLazyQuery(GET_TASKS_WITH_SUBTASKS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      client.writeQuery({
        query: GET_TASKS_WITH_SUBTASKS,
        variables: { workspaceId, projectId },
        data: {
          getTasksWithSubtasks: data.getTasksWithSubtasks,
        },
      });
    },
  });

  const handleFilter = value => {
    console.log('value', value);

    const newFilter = {
      ...filter,
      ...value,
      projectId,
      viewType,
    };

    if (viewType === 'kanban') {
      getFilteredTask({
        variables: {
          projectId,
          assignees: !!newFilter.assignees.length
            ? newFilter.assignees.map(({ value }) => value)
            : undefined,
          dueDate: newFilter.dueDate?.value,
          priority: newFilter.priority?.value,
          statusIds: !!newFilter.statusIds.length
            ? newFilter.statusIds.map(({ value }) => value)
            : undefined,
          labels: !!newFilter.labels.length
            ? newFilter.labels.map(({ value }) => value)
            : undefined,
        },
      });
    } else {
      getTasks({
        variables: {
          workspaceId,
          projectId,
          isShowCompleted: newFilter.isShowCompleted,
          dueDate: newFilter.dueDate?.value,
          statusIds: !!newFilter.statusIds.length
            ? newFilter.statusIds.map(({ value }) => value)
            : undefined,
        },
      });
    }

    updateFilter({ newFilter, reset: value.reset });
  };

  const members = project.members.map(({ fullName, _id }) => ({
    value: _id,
    label: fullName,
  }));

  const filterOptions = [
    {
      id: 'assignees',
      type: 'select',
      label: t('task.assignee.title'),
      options: members,
    },
    {
      id: 'dueDate',
      type: 'select',
      label: t('task.dueDate.title'),
      options: dueDateOptions,
    },
    {
      id: 'statusIds',
      type: 'select',
      label: t('task.status.title'),
      options: statuses.map(({ title, _id }) => ({
        value: _id,
        label: title,
      })),
    },
    {
      id: 'isShowCompleted',
      type: 'checkbox',
      label: 'Show completed',
    },
  ];

  if (viewType === 'kanban') {
    filterOptions.push(
      {
        id: 'priority',
        type: 'select',
        label: t('task.priority.title'),
        options: priorities,
      },
      {
        id: 'labels',
        type: 'select',
        label: 'Labels',
        options: labels.map(({ title, _id }) => ({
          value: _id,
          label: title,
        })),
      },
    );
  }

  return (
    <Box padding={20}>
      <Styled.Header
        alignItems="center"
        justifyContent="space-between"
        padding={[0, 0, 10, 0]}
        margin={[0, 0, 10, 0]}
      >
        <Styled.Title>{'Filters'}</Styled.Title>
        {isFilterActive && (
          <Button
            size="small"
            htmlType="link"
            color="#3F51B5"
            underline={false}
            onClick={() => handleFilter({ ...DEFAULT_FILTER, reset: true })}
          >
            {'Reset'}
          </Button>
        )}
      </Styled.Header>

      <div>
        {filterOptions.map(({ id, type, label, options }) => {
          if (type === 'select') {
            return (
              <Box padding={[15, 0]}>
                <div>{label}</div>
                <Select
                  isMulti
                  isSearchable={false}
                  options={options}
                  value={filter[id]}
                  onChange={value => handleFilter({ [id]: value })}
                />
              </Box>
            );
          }

          if (type === 'checkbox') {
            return (
              <Box padding={[15, 0]}>
                <label>
                  <Flex alignItems="center" gap={8}>
                    <input
                      type="checkbox"
                      checked={filter[id]}
                      onChange={event => {
                        handleFilter({ [id]: event.target.checked });
                      }}
                    />
                    <div>{label}</div>
                  </Flex>
                </label>
              </Box>
            );
          }

          return null;
        })}
      </div>
    </Box>
  );
};

export default ProjectFilter;
