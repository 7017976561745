import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/design-system';

import useOnClickOutside from 'src/hooks/common/useOnClickOutside';
import { useNotifications } from 'src/hooks/useNotifications';

import Notification from './Notification/Notification';
import { openNotifications } from 'src/graphql/vars';

import * as Styled from './styles';

const NotificationsList = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    openNotifications(false);
  });

  const {
    notifications,
    onReadNotification,
    onReadAllNotification,
    loadMoreNotifications,
  } = useNotifications({});

  const handleReadNotification = (notificationId: string) => {
    onReadNotification(notificationId);
  };

  const handleReadAllNotifications = () => {
    onReadAllNotification();
  };

  useEffect(() => {
    const scrollContainer = notificationsRef.current;

    if (!scrollContainer) return;
    const handleScroll = () => {
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const isNear = scrollHeight - scrollTop <= clientHeight + 500;

        if (isNear) {
          loadMoreNotifications({
            variables: {
              skip: notifications.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                notifications: [
                  ...prev.notifications,
                  ...fetchMoreResult.notifications,
                ],
              });
            },
          });
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [loadMoreNotifications, notifications.length]);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Header justifyContent="space-between" alignItems="center">
        <Styled.Title>{t('notifications.title')}</Styled.Title>
        <Button htmlType="link" onClick={handleReadAllNotifications}>
          {t('notifications.readAll')}
        </Button>
      </Styled.Header>
      <Styled.List ref={notificationsRef}>
        {notifications.map(notification => (
          <Notification
            key={notification._id}
            notification={notification}
            handleReadNotification={handleReadNotification}
          />
        ))}
      </Styled.List>
    </Styled.Container>
  );
};

export default NotificationsList;
