import format from 'date-fns/format';

export enum DateFormats {
  DayLongMonth = 'dd LLLL',
  DayLongMonthYear = "LLL dd',' R",
  DayShortMonth = 'MMM d',
  FullDate = "EEE, MMM d 'at' HH:mm aaa",
}

export class DateHelpers {
  static formatDate(date?: Date | null, formatType = DateFormats.DayLongMonth) {
    if (!date) return null;
    return format(new Date(date), formatType);
  }
}
