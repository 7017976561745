import React from 'react';

import { Tag, Flex } from 'src/components/design-system';

import { useLabels } from 'src/hooks/useLabels';

const Labels = ({ action }) => {
  const { labels } = action;

  const { onRemoveLabel } = useLabels();

  return (
    <Flex gap={6} flexWrap="wrap">
      {labels.map(({ _id, title, color }) => (
        <Tag
          id={_id}
          key={_id}
          color={color}
          onClose={() => onRemoveLabel(_id)}
        >
          {title}
        </Tag>
      ))}
    </Flex>
  );
};

export default Labels;
