import { useNavigate } from 'react-router-dom';

import { GET_SUBTASKS } from '../graphql/actions';
import { useQueryParams } from './useQueryParams';
import { sortBy } from 'lodash';

import { useGetSubtasksQuery, useReorderSubtasksMutation } from 'src/generated';

export const useSubtasks = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;

  const { data, loading, error } = useGetSubtasksQuery({
    variables: { parentId: taskId },
  });

  const [reorderSubtasks] = useReorderSubtasksMutation({
    update: (cache, { data }) => {
      if (!data?.reorderSubtasks) return;

      const {
        reorderSubtasks: { subtaskOrder },
      } = data;

      cache.updateQuery(
        {
          query: GET_SUBTASKS,
          variables: { parentId: taskId },
        },
        ({ subtasks }) => ({
          subtasks: sortBy(subtasks, item => subtaskOrder?.indexOf(item._id)),
        }),
      );
    },
  });

  const onGoToSubtask = (subtaskId: string) => {
    query.set('taskId', subtaskId);
    navigate({
      search: query.toString(),
    });
  };

  const onReorderSubtasks = async ({ taskId, subtaskOrder }) => {
    await reorderSubtasks({
      variables: {
        taskId,
        subtaskOrder,
      },
      optimisticResponse: {
        reorderSubtasks: {
          __typename: 'Action',
          _id: taskId,
          subtaskOrder,
        },
      },
    });
  };

  return {
    loading,
    error,
    subtasks: data?.subtasks,
    onGoToSubtask,
    onReorderSubtasks,
  };
};
