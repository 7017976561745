import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import Stickers from '../../../../pages/Project/components/Board/Action/Comments/Stickers/Stickers';
import {
  Flex,
  Avatar,
  Button,
  DEFAULT_EDITOR_DATA,
} from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks/user';
import useOnClickOutside from 'src/hooks/common/useOnClickOutside';
import { useComments } from 'src/hooks/useComments';

import withEmbeds from '../plugins/withEmbeds';
import withLinks from '../plugins/withLinks';
import withTables from '../plugins/withTable';
import { resetSlate } from '../utils/helpers';
import Dropdown from './Dropdown/Dropdown';
import { commands } from './Dropdown/constants';
import Element from './Element';
import Leaf from './Leaf';
import Toolbar from './Toolbar';
import { Editor, Transforms, Range, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { editingCommentVar } from 'src/graphql/vars';

import { CommentTypeEnum } from 'src/generated';

import { EditorContainer } from '../styles';
import {
  Container,
  CommentInputBlock,
  CommentInput,
  CommentActions,
} from './styles';

type SlateCommentEditorProps = {
  readOnly?: boolean;
  taskId?: string;
  placeholder?: string;
  defaultText?: any;
  messageId?: string;
};

const MessageEditor = ({
  taskId,
  readOnly: readOnlyProp,
  placeholder,
  defaultText,
  messageId,
}: SlateCommentEditorProps) => {
  const user = useCurrentUser();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { onUpdateComment, onCreateComment } = useComments();

  const [readOnly, setReadOnly] = useState(readOnlyProp);
  const [target, setTarget] = useState<Range | null>();
  const [index, setIndex] = useState(0);

  const [giphyText, setGiphyText] = useState('');
  const [giphyIndex, setGiphyIndex] = useState(0);
  const [giphyLoading, setGiphyLoading] = useState(false);

  const [command, setCommand] = useState<string | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(
    defaultText ? JSON.parse(defaultText) : DEFAULT_EDITOR_DATA,
  );

  useOnClickOutside(ref, () => {
    setIsFocused(false);
    editingCommentVar(null);
  });

  const editor = useMemo(
    () =>
      withHistory(
        withEmbeds(
          withTables(withLinks(withReact(createEditor() as ReactEditor))),
        ),
      ),
    [],
  );

  const fetchGiphy = useCallback(
    async text => {
      setGiphyLoading(true);
      const currentText = text || giphyText;

      await fetch(
        `https://api.giphy.com/v1/gifs/search?q=${currentText}&limit=1&offset=${giphyIndex}&api_key=vG87Wgv9XjJXRccvutFGGibbqqcCAqgS`,
      )
        .then(res => res.json())
        .then(({ data }) => {
          if (data.length === 0) {
            alert('No more gifs');
            return;
          }

          const giphy = {
            type: 'giphy',
            url: data[0].images.fixed_height.url,
            children: [{ text: '' }],
          };

          // @ts-ignore
          Transforms.setNodes(editor, giphy);
          setValue(editor.children);

          setGiphyIndex(giphyIndex + 1);
          setReadOnly(true);
          setGiphyLoading(false);
        })

        .catch(() => {
          setGiphyLoading(false);
        });
    },
    [editor, giphyIndex, giphyText],
  );

  const removeGiphy = useCallback(() => {
    resetSlate({ editor, setValue });
    setGiphyIndex(0);
    setGiphyText('');
    setReadOnly(false);
    setIsFocused(false);
  }, [editor]);

  const handleAddComment = useCallback(async () => {
    resetSlate({ editor, setValue });

    onCreateComment({
      text: JSON.stringify(value),
      type: CommentTypeEnum.Text,
      taskId,
    });

    removeGiphy();
  }, [editor, onCreateComment, removeGiphy, taskId, value]);

  const renderElement = useCallback(
    props => (
      <Element
        {...props}
        fetchGiphy={fetchGiphy}
        removeGiphy={removeGiphy}
        handleAddComment={handleAddComment}
        giphyLoading={giphyLoading}
      />
    ),
    [fetchGiphy, handleAddComment, removeGiphy, giphyLoading],
  );

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />;
  }, []);

  const handleSelectCommand = useCallback(
    command => {
      resetSlate({ editor, setValue, shouldInsertEmptyBlock: false });

      const text = {
        type: 'paragraph',
        children: [{ text: `${command} ` }],
      };

      // @ts-ignore
      Transforms.insertNodes(editor, text);

      setTarget(null);
      setCommand(command);
    },
    [editor],
  );

  const handleSetFocus = useCallback(() => {
    // TODO add focus to the editor
    setIsFocused(true);
  }, []);

  useEffect(() => {
    messageId && handleSetFocus();
  }, [messageId, handleSetFocus]);

  const handleCloseEditing = useCallback(() => {
    editingCommentVar(null);
  }, []);

  const handleEditComment = useCallback(() => {
    onUpdateComment({
      commentId: messageId,
      text: JSON.stringify(value),
    });
  }, [messageId, onUpdateComment, value]);

  const onKeyDown = useCallback(
    event => {
      if (command) {
        switch (event.key) {
          case 'Tab':
          case 'Enter':
            event.preventDefault();

            const { selection } = editor;

            if (selection && Range.isCollapsed(selection)) {
              const [start] = Range.edges(selection);
              const wordBefore = Editor.before(editor, start, { unit: 'word' });
              const before = wordBefore && Editor.before(editor, wordBefore);
              const beforeRange = before && Editor.range(editor, before, start);
              const beforeText =
                beforeRange && Editor.string(editor, beforeRange);

              fetchGiphy(beforeText);
              // @ts-ignore
              setGiphyText(beforeText);
              setCommand(null);

              // Update the editor value with the empty array
              Transforms.removeNodes(editor, { at: [0] });
              Transforms.insertNodes(editor, DEFAULT_EDITOR_DATA);
            }
            break;
        }
      }

      if (target && commands.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= commands.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? commands.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            const newCommand = commands[index];
            handleSelectCommand(newCommand);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [command, editor, fetchGiphy, handleSelectCommand, index, target],
  );

  const handleUpdateComment = value => {
    setValue(value);

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^\/(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        // console.log('beforeMatch && afterMatch run');
        setTarget(beforeRange);
        // setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  return (
    <Container>
      <Avatar user={user} radius={6} />
      <CommentInputBlock ref={ref}>
        <CommentInput
          focused={isFocused}
          readOnly={readOnly}
          onClick={handleSetFocus}
        >
          <Slate
            editor={editor}
            initialValue={value}
            onChange={handleUpdateComment}
          >
            <EditorContainer>
              <Editable
                id={taskId}
                readOnly={readOnly}
                placeholder={giphyIndex ? '' : placeholder}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={onKeyDown}
              />
              {target && (
                <Dropdown
                  index={index}
                  target={target}
                  handleSelectCommand={handleSelectCommand}
                />
              )}
            </EditorContainer>

            {!readOnly && (
              <CommentActions focused={isFocused}>
                <Toolbar />
                <Flex gap={6} alignItems="center">
                  <Stickers taskId={taskId} />
                  {messageId ? (
                    <Flex gap={10}>
                      <Button onClick={handleCloseEditing}>
                        {t('task.comments.hideEditing')}
                      </Button>
                      <Button onClick={handleEditComment} htmlType="primary">
                        {t('task.comments.editComment')}
                      </Button>
                    </Flex>
                  ) : (
                    <Button onClick={handleAddComment}>
                      {t('task.comments.addComment')}
                    </Button>
                  )}
                </Flex>
              </CommentActions>
            )}
          </Slate>
        </CommentInput>
      </CommentInputBlock>
    </Container>
  );
};

export default MessageEditor;
