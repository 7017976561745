import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;

type ColorProps = {
  size: number;
  color: string;
};

export const Color = styled.div<ColorProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const Title = styled.h4`
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  background: -webkit-linear-gradient(-70deg, #8250df 0%, #d42a32 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-box-decoration-break: clone;
`;

export const Count = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  color: #ccc;
`;

export const HeaderRightBlock = styled.div`
  display: flex;
`;
