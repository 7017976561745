import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import UserSettings from './UserSettings';
import { openUserSettingsDrawer } from 'src/graphql/vars';

const UserSettingsContainer = () => {
  const isOpen = useReactiveVar(openUserSettingsDrawer);
  return (
    <Drawer
      open={isOpen}
      width={300}
      onClose={() => openUserSettingsDrawer(false)}
      mask={false}
    >
      <UserSettings />
    </Drawer>
  );
};

export default UserSettingsContainer;
