import React from 'react';

import { Avatar, Flex } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { NotificationsQuery } from 'src/generated';

import * as Styled from './styles';

type NotificationProps = {
  notification: NotificationsQuery['notifications'][0];
  handleReadNotification: (notificationId: string) => void;
};

const Notification = ({
  notification,
  handleReadNotification,
}: NotificationProps) => {
  const { _id, title, message, isRead, createdAt, createdBy } = notification;

  return (
    <Styled.Notification
      isRead={isRead}
      onClick={() => !isRead && handleReadNotification(_id)}
    >
      <Styled.Title>{title}</Styled.Title>
      <Flex>
        <Avatar user={createdBy} radius={4} />
        <Flex direction="column" gap={10}>
          <div>{message}</div>
          <Styled.NotificationTime>
            {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
          </Styled.NotificationTime>
        </Flex>
      </Flex>
      {!isRead && <Styled.UnreadDot />}
    </Styled.Notification>
  );
};

export default Notification;
