import { useApolloClient } from '@apollo/client';

import { GET_ACTION } from '../../graphql/actions';

export const useTaskById = ({ taskId }) => {
  const client = useApolloClient();

  const data = client.readQuery({
    query: GET_ACTION,
    variables: { id: taskId },
  });

  return data?.action;
};
