import React from 'react';
import { components, ControlProps } from 'react-select';

import * as Styled from './styles';

const StatusesControl = ({ children, ...props }: ControlProps) => {
  const {
    selectProps: {
      value: { color },
    },
  }: any = props;

  return (
    <components.Control {...props}>
      <Styled.StatusesColor color={color} />
      {children}
    </components.Control>
  );
};

export default StatusesControl;
