import React from 'react';

import { useProject } from 'src/utils/apollo';

import { getContrastTextColor } from 'src/utils/colors';

const DisplayStatusDropdown = ({ statusId }) => {
  const { statuses } = useProject();
  const status = statuses.find(({ _id }) => _id === statusId);

  if (!status) {
    return <p>{'No status'}</p>;
  }

  const { title, color: backgroundColor } = status;

  const color = getContrastTextColor({
    backgroundColor,
    themeMode: 'themeMode',
  });

  return (
    <div
      style={{
        height: '50px',
        padding: '14px 10px',
        backgroundColor,
        color,
        fontSize: '15px',
        width: '100%',
      }}
    >
      {title}
    </div>
  );
};

export default DisplayStatusDropdown;
