import React from 'react';

import { useMutation } from '@apollo/client';

import { LineLoader } from 'src/components/design-system';

import TaskModalLeft from './TaskModalLeft/TaskModalLeft';
import TaskModalRight from './TaskModalRight/TaskModalRight';
import { UPDATE_TASK_DESCRIPTION } from 'src/graphql/actions';

import { Container, Row, LoaderContainer } from './styles';

const TaskModal = ({ action, onClose }) => {
  const [, { loading: descriptionLoading }] = useMutation(
    UPDATE_TASK_DESCRIPTION,
  );

  return (
    <Container>
      {descriptionLoading && (
        <LoaderContainer>
          <LineLoader />
        </LoaderContainer>
      )}
      <Row>
        <TaskModalLeft action={action} onClose={onClose} />
        <TaskModalRight action={action} />
      </Row>
    </Container>
  );
};

export default TaskModal;
