import styled from 'styled-components';

export const HistoryItem = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;

export const HistoryItemDate = styled.span`
  padding-left: 5px;
  color: ${({ theme }) => theme.text.active};
`;

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.h3`
  text-align: center;
`;
