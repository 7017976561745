import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from '../fragments';

gql`
  subscription onUpdateTask {
    onUpdateTask {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  subscription onCreateTask {
    onCreateTask {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  subscription ProjectTasksCountSub {
    projectTasksCount {
      totalTasks
      completedTasks
      deletedTasks
      archivedTasks
    }
  }
`;
