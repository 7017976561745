import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from '../../fragments';

gql`
  subscription OnCreateNotification {
    onCreateNotification {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
