import React from 'react';

import { Skeleton } from 'src/components/design-system';

import Flex from '../../Flex/Flex';
import { TableProps } from '../Table';
import TableCell from '../TableCell/TableCell';
import { randomIntFromInterval } from 'src/utils/numbers';

import { Cell } from '../TableCell/styles';
import { Row, RowTitle, RowTitleCount } from './styles';

const TableRow = <T,>({ data, columns, loading }: TableProps<T>) => {
  if (loading) {
    return (
      <>
        {Array.from(Array(randomIntFromInterval(10, 15)).keys()).map(item => (
          <Row key={item}>
            {columns.map((column, columnIndex) => (
              <Cell key={`table-row-cell-${columnIndex}`}>
                <Skeleton height={40} />
              </Cell>
            ))}
          </Row>
        ))}
      </>
    );
  }

  return (
    <>
      {data.map((item, index) => {
        // TODO need to add comment fot this
        // @ts-ignore
        const title = item.rowTitle;
        // @ts-ignore
        const tasksCount = item.tasksCount;

        if (title) {
          return (
            <Row key={`table-body-${index}`}>
              <td colSpan={columns.length}>
                <Flex padding={[20, 6]} gap={10} alignItems="center">
                  <RowTitle>{title}</RowTitle>
                  <RowTitleCount>({tasksCount})</RowTitleCount>
                </Flex>
              </td>
            </Row>
          );
        }

        return (
          <Row key={`table-body-${index}`}>
            {columns.map((column, columnIndex) => (
              <TableCell
                key={`table-row-cell-${columnIndex}`}
                item={item}
                column={column}
                index={index}
              />
            ))}
          </Row>
        );
      })}
    </>
  );
};

export default TableRow;
