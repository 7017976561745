import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from 'src/components/design-system';

import { useLabels } from 'src/hooks/useLabels';

import { useProject } from 'src/utils/apollo';

import LabelInput from './LabelInput';
import { COLORS } from './colors';
import Dropdown from 'rc-dropdown';
import { randomIntFromInterval } from 'src/utils/numbers';

import {
  EditingBlock,
  LabelTag,
  LabelItemBlock,
  LabelsDropdown,
  AddLabelButton,
  Footer,
  LabelBlock,
  DeleteLabelBlock,
} from './styles';

const Labels = ({ action }) => {
  const { t } = useTranslation();
  const { labels } = action;
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenLabelsModal, setIsOpenLabelsModal] = useState(false);
  const { labels: projectLabels } = useProject();

  const { onCreateLabel, onDeleteLabel, onEditLabel, onAddLabelToAction } =
    useLabels();

  const randomColor = useMemo(() => {
    return COLORS[randomIntFromInterval(0, COLORS.length - 1)];
  }, []);

  const handleAddLabelToAction = ({ label }) => {
    onAddLabelToAction({ labels, label });
  };

  const labelsIds = labels.map(({ _id }) => _id);

  const Menu = () => (
    <LabelsDropdown>
      {isEditing ? (
        <EditingBlock>
          {projectLabels.map(label => (
            <LabelBlock key={label._id}>
              <LabelInput
                {...label}
                onSave={newLabel => onEditLabel(label._id, newLabel)}
              />
              <DeleteLabelBlock>
                <Button
                  size="little"
                  htmlType="text"
                  onClick={() => onDeleteLabel(label._id)}
                  icon={<Icon name="close" size={12} />}
                />
              </DeleteLabelBlock>
            </LabelBlock>
          ))}

          <LabelInput color={randomColor.value} onSave={onCreateLabel} />
        </EditingBlock>
      ) : (
        <EditingBlock>
          {projectLabels.map(label => {
            const { _id, title } = label;
            const isAdded = labelsIds.includes(_id);
            return (
              <LabelItemBlock key={_id}>
                <LabelTag
                  {...label}
                  disabled={isAdded}
                  onClick={() => handleAddLabelToAction({ label })}
                >
                  {title}
                </LabelTag>
              </LabelItemBlock>
            );
          })}
        </EditingBlock>
      )}

      <Footer>
        <Button onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? t('task.labels.save') : t('task.labels.edit')}
        </Button>
      </Footer>
    </LabelsDropdown>
  );

  return (
    <>
      <Dropdown
        visible={isOpenLabelsModal}
        trigger={['click']}
        overlay={<Menu />}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger?.parentNode as HTMLElement
        }
        onVisibleChange={e => {
          setIsOpenLabelsModal(e);
          setIsEditing(false);
        }}
      >
        <AddLabelButton onClick={() => setIsOpenLabelsModal(true)}>
          <Icon name="plus-square-fill" color="#5591ea" size={20} />
        </AddLabelButton>
      </Dropdown>
    </>
  );
};

export default Labels;
