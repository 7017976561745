import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { useQueryParams } from 'src/hooks/useQueryParams';

import TaskPreview from './TaskPreview/TaskPreview';

const Action = ({ action, index }) => {
  const { _id } = action;
  const navigate = useNavigate();
  const query = useQueryParams();

  const handleOpenTask = () => {
    query.set('taskId', _id);
    navigate({
      search: query.toString(),
    });
  };

  return (
    <Draggable draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={handleOpenTask}
        >
          <TaskPreview task={action} isDragging={snapshot.isDragging} />
        </li>
      )}
    </Draggable>
  );
};

export default Action;
