import { Button } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type HeaderProps = {
  bg?: string;
};

export const ModalLoader = styled.div`
  height: calc(100vh - 120px);
  max-height: 850px;
`;

export const Row = styled.div`
  display: flex;
  height: calc(100vh - 120px);
  max-height: 850px;
`;

type LeftProps = {
  isDragging?: boolean;
};

export const Left = styled.div<LeftProps>`
  flex: 1;
  overflow-y: auto;
  height: 100%;
  background: ${({ theme }) => theme.task.primary};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.md};

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 6px dashed ${({ theme }) => theme.border.color.secondary};
    `}
`;

export const Right = styled.div`
  width: 251px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  background-color: ${({ theme }) => theme.task.secondary};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.md};
`;

export const RightBlockItem = styled.div`
  margin-bottom: 30px;

  h2 {
    font-weight: bold;
    margin: 0 0 3px 0;
    font-size: 14px;
  }
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  padding: 4px;
  border-radius: 4px 4px 0 0;
  background: ${props => props.bg};
`;
export const CloseButton = styled(Button)`
  padding: 0;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Container = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
