import { makeVar } from '@apollo/client';

import { DEFAULT_FILTER } from '../../pages/Project/components/ProjectFilter/constants';

import { getThemeFromStorage } from 'src/utils/theme';

import { User, Status } from 'src/generated';

type CurrentChapterVar = {
  chapterId: string;
  chapterTime: number;
};

export const currentUserVar = makeVar<User | null>(null);

export const currentThemeVar = makeVar(getThemeFromStorage() || 'system');
export const isOpenSidebarVar = makeVar(true);

export const customFieldsDrawerVar = makeVar(false);

export const openTaskDescriptionHistoryDrawer = makeVar(false);
export const openUnsplashDrawer = makeVar(false);
export const openOpenAIDrawer = makeVar(false);
export const editingCommentVar = makeVar<string | null>(null);

// Books
export const currentChapterVar = makeVar<CurrentChapterVar | null>(null);
export const openUploadBookModalVar = makeVar(false);

export const openNotifications = makeVar(false);

// Project Filter
export const openProjectFilterDrawer = makeVar(false);
export const projectsFiltersVar = makeVar<typeof DEFAULT_FILTER[] | []>([]);

// statuses
export const editStatusVar = makeVar<Pick<
  Status,
  '_id' | 'title' | 'color'
> | null>(null);

// users
export const openUserSettingsDrawer = makeVar(false);

// Project
export const openEditProjectModalVar = makeVar(false);
export const openInviteProjectMembersModalVar = makeVar(false);
