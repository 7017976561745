import React from 'react';

import { Flex, Avatar } from 'src/components/design-system';

import { useProject } from 'src/utils/apollo';

import * as Styled from './styles';

const ProjectMembers = () => {
  const {
    project: { members },
  } = useProject();
  return (
    <div>
      <Styled.Title>{`Project Members (${members.length})`}</Styled.Title>
      <Styled.MembersList>
        {members.map((member: any) => (
          <Styled.Member
            key={member._id}
            gap={10}
            width="100%"
            padding={[10]}
            alignItems="center"
          >
            <Avatar user={member} />
            <Flex direction="column">
              <div>{member.fullName}</div>
              <div>{member.email}</div>
            </Flex>
          </Styled.Member>
        ))}
      </Styled.MembersList>
    </div>
  );
};

export default ProjectMembers;
