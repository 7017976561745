import styled from 'styled-components/macro';

export const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.grayScale.gray45};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.grayScale.gray55};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.grayScale.gray65};
  }
`;
export const List = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  a {
    text-decoration: none;
  }
`;
