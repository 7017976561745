import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { useProjectSubscriptions } from 'src/hooks/useProjectSubscriptions';

import { useProjectFromProjects } from 'src/utils/apollo';

import ProjectInfo from './ProjectInfo';
import MainLayout from 'src/layouts/MainLayout/MainLayout';

const Project = () => {
  const { id } = useParams<{ id: string }>();
  const { title } = useProjectFromProjects(id as string);

  useProjectSubscriptions();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name={title} content={title} />
      </Helmet>
      <MainLayout>
        <ProjectInfo />
      </MainLayout>
    </>
  );
};

export default Project;
