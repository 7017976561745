import React from 'react';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks';

import TableView from './TableView';

import { useGetTasksWithSubtasksQuery } from 'src/generated';

const Table = () => {
  const user = useCurrentUser();
  const { workspaceId } = user;
  const { id: projectId } = useParams<{ id: string }>();

  const { data, loading, error } = useGetTasksWithSubtasksQuery({
    variables: {
      workspaceId,
      projectId,
    },
  });

  if (error) return <p>{`Error! ${error}`}</p>;

  if (loading) return <LoadingIndicator />;

  return <TableView tasks={data?.getTasksWithSubtasks || []} />;
};

export default Table;
