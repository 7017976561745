import { useParams } from 'react-router-dom';

import { useCurrentUser } from '../graphql/hooks';

import {
  useCreateCustomFieldMutation,
  useCustomFieldsQuery,
  useToggleCustomFieldToProjectMutation,
} from 'src/generated';

export const useCustomFields = () => {
  const { id: projectId } = useParams() as any;
  const user = useCurrentUser();
  const { workspaceId } = user;

  const { data, loading, error } = useCustomFieldsQuery({
    variables: {
      workspaceId,
    },
  });

  const [createCustomField] = useCreateCustomFieldMutation({
    update: (cache, { data }) => {
      if (!data?.createCustomField) return;

      cache.modify({
        broadcast: false,
        fields: {
          customFields(existingCustomFields = []) {
            return [data?.createCustomField, ...existingCustomFields];
          },
        },
      });
    },
  });

  const [toggleCustomFieldToProject] = useToggleCustomFieldToProjectMutation({
    update: (cache, { data }) => {
      if (!data?.toggleCustomFieldToProject) return;

      cache.modify({
        fields: {
          customFields(existingCustomFields = []) {
            return existingCustomFields.map((customField: any) => {
              if (customField._id === data.toggleCustomFieldToProject._id) {
                return data.toggleCustomFieldToProject;
              }

              return customField;
            });
          },
        },
      });
    },
  });

  return {
    loading,
    customFields: data?.customFields || [],
    error,
    onCreateCustomField: async ({ title, type, reset }) => {
      await createCustomField({
        variables: { workspaceId, title, type },
      });
      reset();
    },
    onToggleCustomFieldToProject: async ({ customFieldId, isChecked }) => {
      await toggleCustomFieldToProject({
        variables: {
          projectId,
          customFieldId,
          isChecked,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          toggleCustomFieldToProject: {
            __typename: 'CustomField',
            _id: customFieldId,
            projectIds: ['123'],
          },
        },
      });
    },
  };
};
