import React from 'react';

import * as Styled from './styles';

type LineLoaderProps = {
  color?: string;
};

const LineLoader = ({ color }: LineLoaderProps) => {
  return <Styled.LineLoader color={color} />;
};

export default LineLoader;
