import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Select } from 'src/components/design-system';
import PriorityControl from 'src/components/design-system/Select/components/priority/PriorityControl';
import PriorityOption from 'src/components/design-system/Select/components/priority/PriorityOption';
import StatusesControl from 'src/components/design-system/Select/components/statuses/StatusesControl';
import StatusesOption from 'src/components/design-system/Select/components/statuses/StatusesOption';
import StoryPointsOption from 'src/components/design-system/Select/components/storyPoints/StoryPointsOption';
import StoryPointsSingleValue from 'src/components/design-system/Select/components/storyPoints/StoryPointsSingleValue';

import { useTask } from 'src/hooks/useTask';

import { priorities } from 'src/common/constants';

import { useProject } from 'src/utils/apollo';

import DueDate from '../../Board/Action/DueDate/DueDate';
import TaskAssignee from '../../Board/Action/TaskAssignee/TaskAssignee';
import { storyPointsList } from '../../Board/Action/constants';

import * as Styled from './styles';

const TaskModal = ({ action, onClose }) => {
  const { t } = useTranslation();
  const { statuses } = useProject();

  const { _id, priority, statusId, storyPoints, parentId, assignee } = action;

  const currentBoard = statuses.find(({ _id }) => _id === statusId);

  const { onDeleteTask, onUpdateTaskStatus, onUpdateTask } = useTask({
    parentId,
    statusId,
  });

  const handleDeleteAction = () => {
    onDeleteTask({ id: _id });
    onClose();
  };

  const currentPriority = priorities.find(({ value }) => value === priority);
  const taskStoryPoints = storyPointsList.find(
    ({ value }) => value === storyPoints,
  );

  const test = statuses.map(({ _id, title, color }) => ({
    value: _id,
    label: title,
    color,
  }));

  return (
    <Styled.Left>
      <div>
        <Styled.LeftItem>
          <h2>{t('task.assignee.title')}</h2>
          <TaskAssignee assignee={assignee} />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.dueDate.title')}</h2>
          <DueDate task={action} />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.status.title')}</h2>
          <Select
            isSearchable={false}
            value={
              currentBoard && {
                label: currentBoard.title,
                value: currentBoard._id,
                color: currentBoard.color,
              }
            }
            onChange={({ value }) => {
              onUpdateTaskStatus({ taskId: _id, statusId: value });
            }}
            options={test}
            components={{
              Control: StatusesControl,
              Option: StatusesOption,
            }}
          />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.priority.title')}</h2>
          <Select
            isSearchable={false}
            options={priorities}
            value={
              currentPriority ? currentPriority : { value: null, label: 'None' }
            }
            onChange={({ value }) => {
              onUpdateTask({
                taskId: _id,
                priority: value,
              });
            }}
            components={{
              Control: PriorityControl,
              Option: PriorityOption,
            }}
          />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.storyPoints.title')}</h2>
          <Select
            isSearchable={false}
            options={storyPointsList}
            value={taskStoryPoints?.value !== null ? taskStoryPoints : null}
            onChange={data => {
              // data can be null if the user select none story points
              if (data === null) return;
              onUpdateTask({
                taskId: _id,
                storyPoints: data.value,
              });
            }}
            components={{
              SingleValue: StoryPointsSingleValue,
              Option: StoryPointsOption,
            }}
          />
        </Styled.LeftItem>
      </div>

      <Flex gap={16} pushDown direction="column">
        <Button danger onClick={handleDeleteAction}>
          {t('task.buttons.delete')}
        </Button>
      </Flex>
    </Styled.Left>
  );
};

export default TaskModal;
