import React from 'react';
import { useParams } from 'react-router-dom';

import { useProjectFromProjects } from 'src/utils/apollo';

import { openEditProjectModalVar } from '../../../../graphql/vars';

import { Title } from './styles';

const ProjectInfo = () => {
  const { id } = useParams<{ id: string }>();

  const project = useProjectFromProjects(id as string);

  const { title } = project;

  const handleOpenEditModal = () => {
    openEditProjectModalVar(true);
  };

  return (
    <div>
      <Title onClick={handleOpenEditModal}>{title}</Title>
    </div>
  );
};

export default ProjectInfo;
