import styled from 'styled-components';

type Draggable = {
  isDragging?: boolean;
  isDraggingOver?: boolean;
  isEmpty?: boolean;
};

type TaskPreviewContainerProps = {
  top: number;
};

export const Container = styled.div<Draggable>`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  height: 100%;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background: ${({ theme }) => theme.background.primary};
  box-shadow: ${p =>
    p.isDragging ? '0 3px 6px rgba(140, 149, 159, 0.35)' : null};
`;

export const TaskPreviewContainer = styled.li<TaskPreviewContainerProps>`
  position: absolute;
  opacity: 0.5;
  top: ${p => (p ? `${p.top}px` : null)};
  width: 100%;
`;

export const Head = styled.div`
  padding: 5px 0;
`;

export const ColumnList = styled.ul<Draggable>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20px;
  list-style: none;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 270px;
  margin: 0;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background: ${({ theme, isEmpty }) =>
    isEmpty &&
    `linear-gradient(180deg, ${theme.background.secondary}, rgba(42, 43, 45, 0) 100%)`};

  &:not(:hover) {
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  li {
    margin-bottom: 25px;
  }
`;

export const EmptyText = styled.p`
  text-align: center;
  color: #ccc;
  margin: 0;
  font-style: italic;
  padding-top: 10px;
`;
