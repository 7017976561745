import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { useApolloClient, useMutation } from '@apollo/client';

import ProjectSmallInfo from 'src/components/ProjectSmallInfo/ProjectSmallInfo';

import { useProjects } from 'src/utils/apollo';

import { GET_PROJECTS } from '../../../graphql/projects';
import { UPDATE_PROJECTS_ORDER } from 'src/graphql/users';
import { orderArray, reorderArray } from 'src/utils/array';

import { ProjectViews } from 'src/pages/Project/types';

import { MenuLink } from '../styles';
import { Container, List } from './styles';

const ProjectsList = () => {
  const projects = useProjects();
  const params = useParams();
  const client = useApolloClient();

  const [updateProjectsOrder] = useMutation(UPDATE_PROJECTS_ORDER);

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const projectIds = projects.map(({ _id }) => _id);

    const newProjectsOrder = reorderArray(
      projectIds,
      result.source.index,
      result.destination.index,
    );

    client.writeQuery({
      query: GET_PROJECTS,
      data: {
        projects: orderArray({ array: projects, order: newProjectsOrder }),
      },
    });

    updateProjectsOrder({
      variables: {
        projectsOrder: newProjectsOrder,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateProjectsOrder: {
          __typename: 'User',
          projectsOrder: newProjectsOrder,
        },
      },
    });
  };

  return (
    <Container>
      <List>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {projects.map(({ _id, title, color }, index) => (
                  <Draggable key={_id} draggableId={_id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MenuLink
                          to={`/projects/${_id}?view=${ProjectViews.Kanban}`}
                          selected={_id === params.id || snapshot.isDragging}
                        >
                          <ProjectSmallInfo
                            title={title}
                            color={color}
                            isDragging={snapshot.isDragging}
                            isDraggable
                          />
                        </MenuLink>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </Container>
  );
};

export default ProjectsList;
