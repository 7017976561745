import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1050;
  pointer-events: none;
`;

type DrawerMaskProps = {
  style?: React.CSSProperties;
};

export const DrawerMask = styled.div<DrawerMaskProps>`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1050;
  background: rgba(28, 33, 40, 0.8);
  pointer-events: auto;
`;

type ContentWrapperProps = {
  width?: number | string;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: ${({ width }) => width}px;
  right: ${({ width }) => width && -width}px;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1050;
  overflow: hidden;
  transition: transform 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  pointer-events: auto;
  background: ${({ theme }) => theme.background.secondary};
`;
