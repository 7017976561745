import React, { useState } from 'react';

import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

import { useTask } from 'src/hooks/useTask';

import { useProject } from 'src/utils/apollo';

export const Cell = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  border-right: 1px solid ${({ theme }) => theme.border.color.primary};
  margin: 0;
  text-align: left;
  height: 50px;
  width: 100%;
  align-items: center;

  form {
    width: 100%;
  }

  input {
    margin: 0;
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    font-weight: bold;
    font-size: 13px;
    color: ${p => p.theme.text.primary};
    padding: 10px;
  }
`;

const TableCreateTask = () => {
  const [value, setValue] = useState('');
  const { onCreateTask } = useTask({});
  const { project } = useProject();
  const { unstagedStatusId } = project;
  const handleCreateTask = async e => {
    e.preventDefault();

    if (!value) {
      return;
    }

    onCreateTask({ title: value, statusId: unstagedStatusId });
    setValue('');
  };

  return (
    <Cell>
      <Cell style={{ width: '120px', minWidth: '120px' }} />
      <form onSubmit={handleCreateTask}>
        <input
          type="text"
          placeholder="Add a task"
          onBlur={handleCreateTask}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </form>
    </Cell>
  );
};

export default TableCreateTask;
