import { Flex } from 'src/components/design-system';
import styled from 'styled-components/macro';

export const NotificationBlock = styled.div`
  position: relative;
`;
export const Count = styled(Flex)`
  position: absolute;
  top: -8px;
  right: -8px;
  color: #fff;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
  font-weight: 600;
  background: ${({ theme }) => theme.color.success};
`;
