import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import Editor from 'manage-ui-ckeditor5';

const TaskDescription = ({ action, updateTaskDescription }) => {
  const { t } = useTranslation();
  const { _id, description } = action;
  const [value, setValue] = useState(description);

  const handleDebounceFn = newValue => {
    const isSameValue = isEqual(value, newValue);
    if (isSameValue) return;
    updateTaskDescription({
      variables: { id: _id, description: newValue },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleDebounceFn, 400), []);

  const handleUpdateDescription = newValue => {
    setValue(newValue);
    debounceFn(newValue);
  };

  return (
    <CKEditor
      config={{
        placeholder: t('task.description.addDescriptionPlaceholder'),
      }}
      editor={Editor}
      data={value}
      onReady={editor => {}}
      onChange={(event, editor) => {
        const data = editor.getData();
        handleUpdateDescription(data);
      }}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
    />
  );
};

export default TaskDescription;
