import { NOTIFICATIONS } from 'src/graphql/notifications';

import {
  useNotificationsQuery,
  useReadNotificationMutation,
  useReadAllNotificationMutation,
} from 'src/generated';

type UseNotificationsProps = {
  projectId?: string;
};

export const useNotifications = ({ projectId }: UseNotificationsProps) => {
  let variables: any = {};

  if (projectId) {
    variables['projectId'] = projectId;
  }

  const {
    data,
    loading,
    error,
    fetchMore: loadMoreNotifications,
  } = useNotificationsQuery({
    variables,
  });

  const [readNotification] = useReadNotificationMutation();

  const [readAllNotification] = useReadAllNotificationMutation({
    update(cache) {
      const { notifications } = cache.readQuery({
        query: NOTIFICATIONS,
      }) as any;

      cache.writeQuery({
        query: NOTIFICATIONS,
        data: {
          notifications: notifications?.map(notification => ({
            ...notification,
            isRead: true,
          })),
        },
      });
    },
  });

  const unreadCount = data?.notifications.filter(
    notification => !notification.isRead,
  ).length;

  return {
    error,
    loading,
    notifications: data?.notifications || [],
    loadMoreNotifications,
    unreadCount,
    onReadNotification: (notificationId: string) => {
      readNotification({
        variables: {
          notificationId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          readNotification: {
            __typename: 'Notification',
            _id: notificationId,
            isRead: true,
          },
        },
      });
    },
    onReadAllNotification: () => {
      readAllNotification({
        optimisticResponse: {
          __typename: 'Mutation',
          readAllNotification: true,
        },
      });
    },
  };
};
