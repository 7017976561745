import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from '../../fragments';

export const NOTIFICATIONS = gql`
  query Notifications($skip: Int, $limit: Int, $projectId: ID) {
    notifications(skip: $skip, limit: $limit, projectId: $projectId) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
