import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import TaskMoreMenu from './TaskMoreMenu/TaskMoreMenu';
import { useIsTaskCompleted } from 'src/utils/task';

import { Container, CompleteButton } from './styles';

const TaskActions = ({ action, handleCompleteTask, isHeaderFixed }) => {
  const { t } = useTranslation();
  const { statusId, title } = action;
  const theme = useTheme();
  const isTaskCompleted = useIsTaskCompleted(statusId);

  return (
    <Container alignItems="center" gap={20}>
      <Flex alignItems="center" gap={10}>
        <CompleteButton
          isCompleted={isTaskCompleted}
          onClick={handleCompleteTask}
        >
          <Flex alignItems="center" gap={6}>
            {isTaskCompleted && (
              <Icon
                size={18}
                name="circle-check-filled"
                color={theme.color.success}
              />
            )}
            {isTaskCompleted
              ? t('task.buttons.isCompleted')
              : t('task.buttons.complete')}
          </Flex>
        </CompleteButton>
      </Flex>
      {isHeaderFixed && (
        <Flex>
          <strong>{title}</strong>
        </Flex>
      )}
      <Flex pushRight>
        <TaskMoreMenu action={action} />
      </Flex>
    </Container>
  );
};

export default TaskActions;
