import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import Unsplash from './Unsplash';
import { openUnsplashDrawer } from 'src/graphql/vars';

const UnsplashDrawer = () => {
  const isOpen = useReactiveVar(openUnsplashDrawer);

  return (
    <Drawer
      open={isOpen}
      width={700}
      mask={false}
      onClose={() => openUnsplashDrawer(false)}
    >
      <Unsplash />
    </Drawer>
  );
};

export default UnsplashDrawer;
