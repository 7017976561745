import { NOTIFICATIONS } from 'src/graphql/notifications';

import { useOnCreateNotificationSubscription } from 'src/generated';

export const useNotificationSubscriptions = () => {
  useOnCreateNotificationSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const newNotification = subscriptionData.data.onCreateNotification;

      // currently we have 2 types of notifications
      // 1. project related notifications
      // 2. global notifications
      const { projectId } = newNotification;
      const { notifications = [] } =
        client.readQuery({
          query: NOTIFICATIONS,
          ...(projectId && { variables: { projectId } }),
        }) || {};

      client.writeQuery({
        query: NOTIFICATIONS,
        ...(projectId && { variables: { projectId } }),
        data: {
          notifications: [newNotification, ...notifications],
        },
      });
    },
  });
};
