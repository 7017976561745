import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks/user';

import { currentChapterVar } from 'src/graphql/vars';
import { convertSecondsToHMS } from 'src/utils/audio';

import { BooksQuery } from 'src/generated';

import * as Styled from './styles';

type ChapterProps = {
  chapter: BooksQuery['books'][0]['chapters'][0];
};
const Chapter = ({ chapter }: ChapterProps) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const { id, title, time } = chapter;
  const { listening } = user;

  const chapterTime =
    listening?.find(({ chapterId }) => chapterId === id)?.listenedTime || 0;

  const handleStartListeningChapter = () => {
    currentChapterVar({ chapterId: id, chapterTime });
  };

  const progress = `${(chapterTime / time) * 100}%`;

  return (
    <Styled.ChapterItem progress={progress}>
      <Styled.Inner>
        <Flex>
          <Styled.ChapterTitle>{title}</Styled.ChapterTitle>
          <Styled.ChapterTime>{convertSecondsToHMS(time)}</Styled.ChapterTime>
        </Flex>

        <Button htmlType="primary" onClick={handleStartListeningChapter}>
          {chapterTime
            ? `${t('books.continueListening')} ${convertSecondsToHMS(
                chapterTime,
              )}`
            : t('books.startListening')}
        </Button>
      </Styled.Inner>
    </Styled.ChapterItem>
  );
};

export default Chapter;
