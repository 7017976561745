import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag, Flex, Icon, Image } from 'src/components/design-system';
import PriorityIcon from 'src/components/design-system/Select/components/priority/PriorityIcon';
import { useTheme } from 'styled-components';

import { priorities, PRIORITY } from 'src/common/constants';
import { DateHelpers, DateFormats } from 'src/common/helpers';

import { useProject } from 'src/utils/apollo';

import TaskAssignee from '../TaskAssignee/TaskAssignee';
import TaskContextMenu from '../TaskContextMenu/TaskContextMenu';
import isBefore from 'date-fns/isBefore';
import isToday from 'date-fns/isToday';
import { useIsTaskCompleted } from 'src/utils/task';

import { ActionImg, ActionItem, ActionTitle, DateBlock } from './styles';

const TaskPreview = ({ task }: any) => {
  const {
    _id,
    isDragging,
    attachments,
    title,
    labels,
    priority,
    dueDate,
    startDate,
    storyPoints,
    statusId,
    totalSubtasksCount,
    assignee,
    isUrgent,
    isArchived,
  } = task;
  const { t } = useTranslation();
  const theme = useTheme();
  const isOverdue = isBefore(new Date(dueDate), new Date());
  const triggerRef = useRef<HTMLDivElement>(null);

  const currentPriority = priorities.find(({ value }) => value === priority);

  const attachment = Array.isArray(attachments) ? attachments[0] : null;

  const { project } = useProject();
  const isTaskCompleted = useIsTaskCompleted(statusId);

  const today = isToday(new Date(dueDate));

  const { color } = project;

  return (
    <>
      <ActionItem ref={triggerRef} isDragging={isDragging} color={color}>
        {attachment && (
          <ActionImg key={attachment._id}>
            <Image
              src={attachment.url}
              alt={attachment.title}
              preview={attachment.previewImage}
            />
          </ActionImg>
        )}

        <Flex direction="column" gap={16} padding={12}>
          <Flex alignItems="center">
            <ActionTitle completed={isTaskCompleted}>{title}</ActionTitle>
            <TaskAssignee taskId={_id} assignee={assignee} compact />
          </Flex>

          <Flex gap={6} flexWrap="wrap" style={{ minHeight: '30px' }}>
            {labels.map(({ _id, title, color }) => (
              <Tag key={_id} id={_id} color={color}>
                {title}
              </Tag>
            ))}
          </Flex>

          <Flex alignItems="center" gap={8}>
            {isArchived && <Icon name="archive" color={theme.color.grey500} />}
            {isUrgent && (
              <Icon
                size={20}
                name="info-triangle-filled"
                color={theme.color.urgent}
              />
            )}
            {currentPriority && currentPriority.value !== PRIORITY.NONE && (
              <PriorityIcon {...(currentPriority as any)} />
            )}

            {(startDate || dueDate) && (
              <DateBlock
                gap={4}
                alignItems="center"
                isOverdue={isOverdue}
                isCompleted={isTaskCompleted}
                today={today}
              >
                {startDate && (
                  <>
                    <strong>
                      {DateHelpers.formatDate(
                        startDate,
                        DateFormats.DayShortMonth,
                      )}
                    </strong>
                    <strong>-</strong>
                  </>
                )}
                {dueDate && (
                  <strong>
                    {DateHelpers.formatDate(dueDate, DateFormats.DayShortMonth)}
                  </strong>
                )}
              </DateBlock>
            )}

            {storyPoints && (
              <div>
                {t('task.storyPoints.points')}: <b>{storyPoints}</b>
              </div>
            )}
            {totalSubtasksCount && (
              <Flex alignItems="center" gap={2}>
                <Icon name="subtasks" size={14} />
                {totalSubtasksCount}
              </Flex>
            )}
          </Flex>
        </Flex>
      </ActionItem>
      <TaskContextMenu task={task} triggerRef={triggerRef} />
    </>
  );
};

export default TaskPreview;
