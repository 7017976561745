import { Table, Row } from '@tanstack/react-table';
import React, { CSSProperties } from 'react';

import { useIsTaskCompleted } from '../../../../../utils/task';
import TableCell from '../TableCell/TableCell';
import { useSortable } from '@dnd-kit/sortable';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TableTask } from '../types';

import * as Styled from './styles';

type TrowProps = {
  row: Row<TableTask>;
  table: Table<TableTask>;
  columnOrder: string[];
  setEditingCell: (value: string | null) => void;
  editingCell: string | null;
};

const Trow = ({
  row,
  table,
  columnOrder,
  setEditingCell,
  editingCell,
}: TrowProps) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original._id,
  });

  const rowStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    position: isDragging ? 'relative' : 'static',
    zIndex: isDragging ? 1 : 0,
    boxShadow: isDragging ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'none',
  };

  const isRowSelected = Boolean(table.getSelectedRowModel().rowsById[row.id]);
  const { statusId, priority } = row.original;
  const isCompleted = useIsTaskCompleted(statusId);

  return (
    <Styled.TableRow
      key={row.id}
      ref={setNodeRef}
      alignItems="center"
      style={rowStyle}
    >
      {row.getVisibleCells().map(cell => {
        return (
          <SortableContext
            key={cell.id}
            items={columnOrder}
            strategy={horizontalListSortingStrategy}
          >
            <TableCell
              key={cell.id}
              cell={cell}
              setEditingCell={setEditingCell}
              editingCell={editingCell}
              isCompleted={isCompleted}
              isRowSelected={isRowSelected}
              priority={priority}
            />
          </SortableContext>
        );
      })}
    </Styled.TableRow>
  );
};

export default Trow;
