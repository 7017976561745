import React, { useEffect, useState } from 'react';

import { usePrevious } from 'src/hooks/common/usePrevious';
import { useTask } from 'src/hooks/useTask';

import { isEqual } from 'lodash';

import { TitleBlock } from './styles';

const Title = ({ taskId, title }) => {
  const [value, setValue] = useState(title);
  const prevValue = usePrevious(value);
  const { onUpdateTask } = useTask({});

  // update value when title changes
  useEffect(() => {
    setValue(title);
  }, [title]);

  const handleBlur = () => {
    const isSameValue = isEqual(prevValue, value);
    if (isSameValue || prevValue === undefined) return;
    onUpdateTask({ taskId, title: value });
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <TitleBlock>
      <input
        type="text"
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Please enter a title for this task"
      />
    </TitleBlock>
  );
};

export default Title;
