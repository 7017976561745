import React, { useCallback } from 'react';

import { Icon, Image } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useTask } from 'src/hooks/useTask';

import { useProject } from 'src/utils/apollo';

import { useIsTaskCompleted } from 'src/utils/task';

import { GetSubtasksQuery } from 'src/generated';

import * as Styled from './styles';

type SubTasksProps = {
  task: GetSubtasksQuery['subtasks'][0];
  onGoToSubtask: (id: string) => void;
  dragHandleProps: any;
  isDragging: boolean;
};
const SubTask = ({
  task,
  onGoToSubtask,
  dragHandleProps,
  isDragging,
}: SubTasksProps) => {
  const { _id, title, parentId, statusId, attachments } = task;
  const firstAttachment = attachments && attachments[0];
  const isTaskCompleted = useIsTaskCompleted(statusId);
  const theme = useTheme();
  const { project } = useProject();
  const { completedStatusId, unstagedStatusId } = project;

  const { onUpdateTaskStatus } = useTask({
    statusId,
    parentId,
  });

  const handleUpdateTaskStatus = useCallback(
    e => {
      e.stopPropagation();
      onUpdateTaskStatus({
        taskId: _id,
        statusId: isTaskCompleted ? unstagedStatusId : completedStatusId,
      });
    },
    [
      _id,
      completedStatusId,
      isTaskCompleted,
      onUpdateTaskStatus,
      unstagedStatusId,
    ],
  );

  return (
    <Styled.Container gap={10} alignItems="center" isDragging={isDragging}>
      <Styled.ReorderIcon {...dragHandleProps}>
        <Icon name="reorder" />
      </Styled.ReorderIcon>
      <Styled.Subtask
        gap={10}
        alignItems="center"
        onClick={() => onGoToSubtask(_id)}
      >
        <div onClick={handleUpdateTaskStatus}>
          <Icon
            name={isTaskCompleted ? 'circle-check-filled' : 'circle-check'}
            color={isTaskCompleted ? theme.color.success : ''}
          />
        </div>
        {firstAttachment && (
          <Styled.Attachment>
            <Image src={firstAttachment.url} alt="" />
          </Styled.Attachment>
        )}
        <Styled.Title isTaskCompleted={isTaskCompleted}>{title}</Styled.Title>
      </Styled.Subtask>
    </Styled.Container>
  );
};

export default SubTask;
