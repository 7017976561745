import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

const HEADER_HEIGHT = 40;
export const Container = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  bottom: 5px;
  width: 500px;
  z-index: 1050;
  background: ${({ theme }) => theme.background.secondary};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 8px 0 0 8px;
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 72px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.background.secondary};
  }
`;

export const Header = styled(Flex)`
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  padding: 4px;
  text-align: right;
`;

export const Title = styled.div`
  margin: 0;
  font-weight: bold;
  padding-left: 10px;
`;

export const List = styled.div`
  overflow-y: auto;
  height: calc(100% - ${HEADER_HEIGHT}px);
`;
