import React, { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { ThemeProvider as OriginalThemeProvider } from 'styled-components';

import { isSystemDark } from '../../utils/theme';
import { darkDimmedTheme } from './dark-dimmed-theme';
import { darkTheme } from './dark-theme';
import { lightTheme } from './light-theme';
import { currentThemeVar } from 'src/graphql/vars/vars';

import { THEME } from './types';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  const theme = useReactiveVar(currentThemeVar);

  const currentTheme = useMemo(() => {
    if (theme === THEME.DARK) {
      return darkTheme;
    }

    if (theme === THEME.LIGHT) {
      return lightTheme;
    }

    if (theme === THEME.DARK_DIMMED) {
      return darkDimmedTheme;
    }

    if (theme === THEME.SYSTEM) {
      if (isSystemDark) {
        return darkTheme;
      } else {
        return lightTheme;
      }
    }

    return darkTheme;
  }, [theme]);

  return (
    <OriginalThemeProvider theme={currentTheme}>
      {React.Children.only(props.children)}
    </OriginalThemeProvider>
  );
};
