import React from 'react';

import { useReactiveVar } from '@apollo/client';

import NotificationsList from './NotificationsList';
import { openNotifications } from 'src/graphql/vars';

const Notifications = () => {
  const isOpenNotifications = useReactiveVar(openNotifications);

  if (!isOpenNotifications) return null;

  return <NotificationsList />;
};

export default Notifications;
