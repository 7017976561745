import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Notification } from './Notification';
import { NotificationContext } from './NotificationContext';

import { NotificationCard, INotification, IConfig } from './types';

import { Container } from './styles';

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationCard[]>([]);

  const configData: Required<IConfig> = {
    isCloseable: false,
    showTitle: true,
    position: 'bottom-right',
    duration: 10,
    errorColor: 'red',
    successColor: 'green',
    infoColor: 'blue',
    closeIcon: null,
    successIcon: null,
    errorIcon: null,
    infoIcon: null,
    showIcon: true,
    animationDuration: 500,
  };

  // Show Notification
  const show = useCallback((data: INotification) => {
    const defaultData: Required<INotification> = {
      variant: 'info',
      duration: 10,
      message: '',
      title: '',
    };

    const value = {
      ...defaultData,
      ...data,
    };

    const id = Math.random();
    const expiresAt = new Date();
    const secondsToAdd = value.duration;
    expiresAt.setSeconds(expiresAt.getSeconds() + secondsToAdd);

    const notification: NotificationCard = {
      ...value,
      id,
      animate: true,
      expiresAt: value.duration ? expiresAt : undefined,
    };

    setNotifications(current => [
      ...current.map(c => ({
        ...c,
      })),
      notification,
    ]);

    return id;
  }, []);

  // Close notification
  const remove = useCallback((id: number) => {
    setNotifications(current => [
      ...current.map(c => {
        if (c.id === id) {
          return {
            ...c,
            animate: false,
          };
        }
        return c;
      }),
    ]);

    setNotifications(current => [
      ...current.filter(c => {
        return c.id !== id;
      }),
    ]);

    // remove notification after animation
    // setTimeout(() => {
    //   setNotifications(current => [
    //     ...current.filter(c => {
    //       return c.id !== id;
    //     }),
    //   ]);
    // }, configData.animationDuration);
  }, []);

  useEffect(() => {
    let intervalId;

    if (!notifications.length) {
      clearInterval(intervalId);
      return;
    }

    intervalId = setInterval(() => {
      setNotifications(current =>
        current.map(c => {
          if (!c.expiresAt || c.expiresAt > new Date()) {
            return c;
          } else {
            remove(c.id);
            return { ...c, animate: false };
          }
        }),
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [notifications.length, remove]);

  const contextValue = useMemo(() => ({ show }), [show]);

  console.log('re-render notifications');

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}

      {!!notifications.length &&
        createPortal(
          <Container>
            {notifications.map(notification => (
              <Notification
                remove={remove}
                key={notification.id}
                notification={notification}
              />
            ))}
          </Container>,
          document.body,
        )}
    </NotificationContext.Provider>
  );
};
