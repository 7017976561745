import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { Modal, FormItem, Button, Colors } from 'src/components/design-system';

import { useProjects } from 'src/hooks/useProjects';

import { useProjectFromProjects } from 'src/utils/apollo';

import { openEditProjectModalVar } from 'src/graphql/vars';

import { ModalHeader, ModalFooter } from './styles';

type EditProjectValues = {
  title: string;
  color: string;
};

const EditProject = () => {
  const { t } = useTranslation();
  const isOpenEditProjectModal = useReactiveVar(openEditProjectModalVar);
  const { id } = useParams<{ id: string }>();
  const project = useProjectFromProjects(id as string);

  const { onDeleteProject, onUpdateProject } = useProjects();

  const { _id, title, color } = project;

  const methods = useForm<EditProjectValues>({
    defaultValues: {
      title,
      color,
    },
  });

  const { handleSubmit, reset, setValue } = methods;

  const handleCloseModal = () => {
    reset();
    openEditProjectModalVar(false);
  };

  useEffect(() => {
    reset({ title, color });
  }, [color, reset, title]);

  const handleDeleteProject = () => {
    openEditProjectModalVar(false);
    onDeleteProject({ projectId: _id });
  };

  const onSubmit = async ({ title, color }) => {
    await onUpdateProject({ projectId: _id, title, color });
    handleCloseModal();
  };

  return (
    <Modal
      width={500}
      onRequestClose={() => openEditProjectModalVar(false)}
      isOpen={isOpenEditProjectModal}
    >
      <ModalHeader>
        <h2>{t('project.edit.title')}</h2>
      </ModalHeader>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem name="title">
            <input
              type="text"
              placeholder={t('project.edit.titlePlaceholder')}
            />
          </FormItem>

          <FormItem name="color">
            <Colors
              value={color}
              onChange={color => {
                setValue('color', color);
              }}
            />
          </FormItem>

          <div>
            <h4>{t('project.edit.advancedSettings')}</h4>

            <Button type="button" onClick={handleDeleteProject}>
              {t('project.edit.delete')}
            </Button>
          </div>

          <ModalFooter>
            <Button onClick={handleCloseModal}>
              {t('project.edit.cancel')}
            </Button>
            <Button htmlType="primary">{t('project.edit.save')}</Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditProject;
