import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { DEFAULT_FILTER } from 'src/pages/Project/components/ProjectFilter/constants';

import { addOrReplaceBy } from '../../utils/array';
import isEqual from 'lodash/isEqual';
import { projectsFiltersVar } from 'src/graphql/vars';

export const useProjectFilter = () => {
  const { id: projectId } = useParams() as any;
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');
  const projectsFilters = useReactiveVar(projectsFiltersVar);

  const filter = useMemo(() => {
    return projectsFilters.find(
      filter => filter.projectId === projectId && filter.viewType === viewType,
    );
  }, [viewType, projectId, projectsFilters]);

  const isFilterActive = useMemo(() => {
    if (!filter) {
      return false;
    }

    return !isEqual(
      { ...filter, projectId: null, viewType: null },
      DEFAULT_FILTER,
    );
  }, [filter]);

  return {
    isFilterActive,
    filter: filter || DEFAULT_FILTER,
    updateFilter: ({ newFilter, reset }: any) => {
      if (reset) {
        projectsFiltersVar(
          projectsFilters.filter(({ projectId: id }) => id !== projectId),
        );
        return;
      }
      const updatedFilters = addOrReplaceBy(projectsFilters, newFilter, [
        'projectId',
        'viewType',
      ]);

      projectsFiltersVar([...projectsFilters, ...updatedFilters]);
    },
  };
};
