import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { LoadingIndicator } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useCurrentUser } from 'src/graphql/hooks';

import DashboardTasksByPeriod from 'src/widgets/DashboardTasksByPeriod/DashboardTasksByPeriod';

import TasksBySection from './TasksBySection';
import { GET_TASK_DASHBOARD } from 'src/graphql/actions';

import { Container, Card, CardContainer, Title, Count } from './styles';

const Dashboard = () => {
  const user = useCurrentUser();
  const { workspaceId } = user;
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();

  const { data, loading, error, refetch } = useQuery(GET_TASK_DASHBOARD, {
    variables: { workspaceId, projectId: id },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <LoadingIndicator />;

  if (error) return <p>{'Error :('}</p>;

  const { totalTasks, completedTasks } = data.taskCounts;

  return (
    <Container>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.completedTasks')}</Title>
          <Count color={theme.color.success}>{completedTasks}</Count>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.incompleteTasks')}</Title>
          <Count>{totalTasks - completedTasks}</Count>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.totalTasks')}</Title>
          <Count>{totalTasks}</Count>
        </Card>
      </CardContainer>

      <CardContainer width={'100%'}>
        <Card>
          <Title>{t('project.dashboard.tasksByStatus')}</Title>
          <TasksBySection data={data.taskByStatusWithCount} />
        </Card>
      </CardContainer>

      <CardContainer width={'100%'}>
        <DashboardTasksByPeriod projectId={id} />
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
