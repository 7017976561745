import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { Box, Menu, Flex, Avatar } from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks';

import { user_more_settings, USER_MORE_SETTINGS } from './constants';
import { currentUserVar, openUserSettingsDrawer } from 'src/graphql/vars';
import Auth from 'src/utils/auth';

const UserSettings = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const user = useCurrentUser();

  const handleMenuClick = useCallback(
    (id: any) => {
      switch (id) {
        case USER_MORE_SETTINGS.SETTINGS:
          navigate('/settings/profile');
          break;
        case USER_MORE_SETTINGS.LOGOUT:
          currentUserVar(null);
          Auth.logout();
          client.resetStore();
          break;
        default:
          break;
      }

      openUserSettingsDrawer(false);
    },
    [client, navigate],
  );

  return (
    <Box padding={10}>
      <Flex alignItems="center" margin={[0, 0, 20, 0]}>
        <Avatar user={user} />
        <Flex direction="column">
          <Box bold>{user?.fullName}</Box>
          <Box>{user?.email}</Box>
        </Flex>
      </Flex>
      <Menu
        clean
        options={user_more_settings}
        handleMenuClick={handleMenuClick}
      />
    </Box>
  );
};

export default UserSettings;
