import React from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { Avatar, Button, Flex, Icon } from 'src/components/design-system';

import { useCurrentUser } from 'src/graphql/hooks';

import HeaderTitle from './HeaderTitle/HeaderTitle';
import OpenAI from './OpenAI/OpenAI';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import TimeTracking from './TimeTracking/TimeTracking';
import TopNotificationButton from './TopNotificationButton/TopNotificationButton';
import { isOpenSidebarVar, openUserSettingsDrawer } from 'src/graphql/vars';

import { TopHeader } from '../styles';

const FirstMenu = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const isOpenSidebar = useReactiveVar(isOpenSidebarVar);
  const user = useCurrentUser();

  const handleShowSetting = () => {
    openUserSettingsDrawer(true);
  };

  return (
    <TopHeader alignItems="center" padding={8}>
      <Flex alignItems="center" flex="1" gap={10}>
        {!isOpenSidebar && (
          <Button size="small" onClick={() => isOpenSidebarVar(true)}>
            <Icon name="menu" size={24} />
          </Button>
        )}
        <HeaderTitle />
        {projectId && <ProjectInfo />}
        {projectId && <ProjectMembers />}
      </Flex>
      <Flex alignItems="center" gap={10}>
        <TimeTracking />
        {/*<OpenAI />*/}
        <TopNotificationButton />
        <Flex onClick={handleShowSetting} alignItems="center">
          <Avatar user={user} size={36} />
        </Flex>
      </Flex>
    </TopHeader>
  );
};

export default FirstMenu;
