import React from 'react';

import { Icon } from 'src/components/design-system';

import { convertHexToRGBA } from 'src/utils/colors';

import * as Styled from './styles';

interface TagProps {
  id?: string;
  children: React.ReactNode;
  color: string;
  onClose?: (id: string) => void;
  onClick?: () => void;
  disabled?: boolean;
}

const Tag = ({ id, children, color, onClose, ...rest }: TagProps) => {
  return (
    <>
      <Styled.Tag
        {...rest}
        color={color}
        bg={convertHexToRGBA(color, 0.2)}
        hoverBg={convertHexToRGBA(color, 0.4)}
      >
        <Styled.Title color={color}>{children}</Styled.Title>
        {id && onClose && (
          <Styled.Close
            bg={color}
            size="little"
            type="button"
            onClick={() => onClose(id)}
          >
            <Icon name="close-tag" size={20} color={color} />
          </Styled.Close>
        )}
      </Styled.Tag>
    </>
  );
};

export default Tag;
