import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      _id
      title
      color
      boardsSortList
      createdAt
      members {
        _id
        email
        fullName
        profilePicture
      }
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query GetProject($projectId: ID!) {
    project(projectId: $projectId) {
      _id
      title
      color
      boardsSortList
      completedStatusId
      unstagedStatusId
      members {
        _id
        email
        fullName
        profilePicture
      }
    }
    labels(projectId: $projectId) {
      _id
      title
      color
    }
    statuses(projectId: $projectId) {
      _id
      title
      color
    }
  }
`;

gql`
  query ProjectsDashboard {
    projectsDashboard {
      _id
      title
      color
      members {
        _id
        fullName
        profilePicture
      }
      createdAt
      updatedAt
      statusesList
      labelsList
      statusesCount
      labelsCount
      taskCount
      filesCount
    }
  }
`;
