import React from 'react';

import { Flex, Avatar, serializeNodes } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { NotificationsQuery } from 'src/generated';

import * as Styled from './styles';

type ProjectActivityProps = {
  notification: NotificationsQuery['notifications'][0];
};

const ProjectActivity = ({ notification }: ProjectActivityProps) => {
  const { title, message, createdBy, createdAt } = notification;

  return (
    <Styled.Container
      alignItems="center"
      margin={[10, 0]}
      padding={[0, 0, 10, 0]}
    >
      <Avatar user={createdBy} radius={4} />
      <Flex direction="column">
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
        {message && (
          <div
            dangerouslySetInnerHTML={{
              __html: serializeNodes(JSON.parse(message)),
            }}
          ></div>
        )}
        <div>{DateHelpers.formatDate(createdAt, DateFormats.FullDate)}</div>
      </Flex>
    </Styled.Container>
  );
};

export default ProjectActivity;
