import { gql } from '@apollo/client';

export const UPDATE_PROJECTS_ORDER = gql`
  mutation UpdateProjectsOrder($projectsOrder: [ID!]!) {
    updateProjectsOrder(input: { projectsOrder: $projectsOrder }) {
      projectsOrder
    }
  }
`;

gql`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $role: UserRoleEnum
    $file: Upload
  ) {
    updateUserProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        role: $role
        file: $file
      }
    ) {
      _id
      profilePicture
      firstName
      lastName
      fullName
      email
      role
    }
  }
`;
