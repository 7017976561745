import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, LoadingIndicator } from 'src/components/design-system';

import { useNotifications } from 'src/hooks/useNotifications';

import ProjectActivity from './ProjectActivity/ProjectActivity';

const Overview = () => {
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();
  const { loading, notifications } = useNotifications({ projectId });

  if (loading) return <LoadingIndicator />;

  if (!notifications.length) {
    return (
      <Box padding={20}>
        <p>{t('project.activity.noActivity')}</p>
      </Box>
    );
  }

  return (
    <Box padding={20}>
      <h2>{t('project.activity.title')}</h2>

      {notifications.map(notification => (
        <ProjectActivity key={notification._id} notification={notification} />
      ))}
    </Box>
  );
};

export default Overview;
